import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },  {
    path: 'driver',
    loadChildren: () => import('./driver/driver.module').then( m => m.DriverPageModule)
  },
  {
    path: 'driver-detail',
    loadChildren: () => import('./driver-detail/driver-detail.module').then( m => m.DriverDetailPageModule)
  }

  // {
  //   path: 'do/:id',
  //   loadChildren: () => import('./do/do.module').then( m => m.DOPageModule)
  // },
  // {
  //   path: 'do-verification',
  //   loadChildren: () => import('./do-verification/do-verification.module').then( m => m.DOVerificationPageModule)
  // }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
