import { Component } from '@angular/core';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  private lastTimeBackPress = 0;
  private timePeriodToExit = 2000;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastController: ToastController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.backButtonEvent();
    });
  }

  backButtonEvent() {
    if (this.platform.is("android")) {
      this.platform.backButton.subscribeWithPriority(0, () => {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          navigator['app'].exitApp();
        } else {
          this.presentToast();
          this.lastTimeBackPress = new Date().getTime();
        }
      });
    }
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: "Press Back again to Exit",
      duration: 3000,
      position: "bottom",
      cssClass: "exitToast"
    });

    return await toast.present();
  }
}
